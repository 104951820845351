<template>
  <section class="section">
    <div class="container">
      <div class="blog">
        <h2 class="title blog__title">Будьте в курсе новостей</h2>
        <p class="blog__text text text--grey">Новости, советы и полезные статьи</p>
        <div class="articles">
          <ul class="articles__list" v-if="!errorMessage">
            <li
              v-for="(article, index) in articles"
              :key="`article-${index}`"
              :class="`articles__item ${index === 0 ? 'articles__item--main' : ''}`"
            >
              <article :class="`article ${index === 0 ? 'article--main' : ''}`">
                <img
                  v-if="article.attributes.thumbnail.data"
                  :src="mediaPath + article.attributes.thumbnail.data.attributes.url"
                  class="article__img"
                />
                <div class="article__content">
                  <span class="article__date">{{ useDateFormat(article.attributes.releaseDate, 'DD.MM.YYYY').value }}</span>
                  <span :class="`article__text ${index === 0 ? 'text text--grey' : ''}`">{{ article.attributes.title }}</span>
                  <nuxt-link class="article__link" :to="'/news/' + article.attributes.seoUrl"> Читать </nuxt-link>
                </div>
              </article>
            </li>
          </ul>
          <div v-else>{{ errorMessage }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useDateFormat } from '@vueuse/shared/index';
const { find } = useStrapi();
const mediaPath = useStrapiMedia();
const route = useRoute();
const errorMessage = ref<string>('');
let articles = null;

try {
  const response = await find('tnews', {
    publicationState: 'live',
    populate: '*',
    sort: 'publishedAt:desc',
    pagination: route.path === '/news' ? { start: 0 } : { start: 0, limit: 4 },
  });

  articles = response.data;
} catch (error) {
  errorMessage.value = 'Не удалось загрузить ленту новостей';
}
</script>

<style scoped lang="scss">
.blog {
  &__title {
    max-width: 464px;
    margin-bottom: 12px;
  }

  &__text {
    margin-bottom: 35px;
    font-weight: var(--bold);
  }

  .articles {
    display: flex;
    gap: 52px;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: 2fr;
      gap: 56px;
      width: 100%;
    }

    &__item {
      &--main {
        grid-column: span 1;
        grid-row: span 4;
      }

      @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
          grid-column: span 1;
        }
      }

      grid-column: span 2;
    }
  }

  .article {
    display: flex;
    gap: 30px;

    &--main {
      flex-direction: column;
      .article__img {
        width: 100%;
        max-width: 100%;
      }
    }

    &__img {
      border-radius: 32px;
      object-fit: contain;
      height: auto;
      align-self: flex-start;
      max-width: 216px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    &__text {
      font-size: 20px;
      line-height: 24px;
      color: var(--subtitle-color);
    }

    &__date {
      font-size: 16px;
      line-height: 20px;
      color: var(--light-grey);
    }

    &__title {
      font-weight: var(--bold);
    }

    &__link {
      text-decoration: underline;
      color: var(--accent-color);
      font-size: 20px;
      line-height: 24px;
      margin-top: auto;
    }
  }
}

@media (max-width: 1180px) {
  .blog {
    .articles {
      flex-direction: column;
      gap: 32px;

      &__list {
        gap: 40px;
        grid-template-columns: 1fr;
      }

      &__item {
        grid-column: span 2 !important;
        grid-row: span 1;
      }
    }

    .article {
      &--main {
        flex-direction: row;
        width: 100%;
        max-width: 100%;

        .article__img {
          object-fit: contain;
          align-self: auto;
          width: 50%;
          flex: 0 0 50%;
        }
      }

      &__link {
        margin: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .blog {
    .article {
      flex-direction: column;

      &--main {
        flex-direction: column;
        gap: 30px;
        .article__img {
          max-width: 100%;
          width: auto;
          flex: 0 0 100%;
          object-position: 0 100%;
        }
      }
      &__img {
        max-width: 100%;
        width: auto;
        object-position: 0 100%;
      }
    }
  }
}
</style>
